import {  useAuth } from 'utils/AuthContext';
import {Navigate } from 'react-router-dom';


function PrivateRoute() {
  const { isAuthenticated } = useAuth();
 
   return isAuthenticated ? <Navigate to="/" /> : <Navigate to="/" />;
  };
  

export default PrivateRoute;