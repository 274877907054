import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';


import PrivateRoute from './PrivateRoute';



// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));


// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
 
    {
      path: '*',
      element: <PrivateRoute />
    }

    ]
};

export default LoginRoutes;
