import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';

export const AuthContext = createContext();
const protocol = window.location.protocol;
const hostname = window.location.hostname;
const protocolAndHostname = protocol + '//' + hostname;


export const deviceApiUrl=`${protocolAndHostname}:8000/ha_session`
export const refreshUrl=`${protocolAndHostname}:8000/ha_login`


// export const deviceApiUrl=`http://35.174.137.70/ha_session`

// const refreshUrl=`http://172.16.16.125:8081/ha_login`



// export const deviceApiUrl=`http://35.174.137.70:8000/ha_session`

// export const refreshUrl=`http://35.174.137.70:8000/ha_login`
export const apiKey='9BpQ4fXoT7sZ2gR1aE6cY3wH8uJ5mL0v'



export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
 var isAuthenticated= Cookies.get("Authenticate");

 const [userData, setUserData] = useState([]);
const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await axios.post(`${refreshUrl}/refresh_token`, { refresh_token: refreshToken  }, { headers: {
        key: `${apiKey}`,
      },
        
      });
      const newAccessToken = response.data.access_token;
      const newRefreshToken = response.data.refresh_token; 
  
      localStorage.setItem('refreshToken', newRefreshToken); 
  
      setAccessToken(newAccessToken);
    } catch (error) {
      console.error('Token refresh error:', error);
      window.location.href = "/";
      Cookies.remove('Authenticate');

  localStorage.removeItem('refreshToken');
    }
  };
  





  useEffect(() => {
    if (isAuthenticated) {
      const storedRefreshToken = localStorage.getItem('refreshToken');
    
      refreshAccessToken(storedRefreshToken);

    
    }
  }, []);





  useEffect(() => {
    if(accessToken){
      fetchUserData(accessToken)
    }
    
  
  }, [accessToken]);
  
  const fetchUserData = async (accessToken) => {
    try {
      const response = await axios.get(`${refreshUrl}/user`, {
        headers: {
          Authorization:`${accessToken}`,
          key: `${apiKey}`,
        },
      });

      setUserData(response.data.data);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  
  useEffect(() => {

    const intervalId = setInterval(() => {
      if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000; 

        if (decodedToken.exp < currentTime) {
         
          window.location.href = "/";
          Cookies.remove('Authenticate');

  localStorage.removeItem('refreshToken');
        }
      }
    }, 60 * 1000); 

    
    return () => clearInterval(intervalId);
  }, [accessToken]);





  return (
    <AuthContext.Provider value={{accessToken, setAccessToken,userData ,isAuthenticated,}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
