
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';


// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'utils/AuthContext';


// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); 
// window.oncontextmenu = function (event) {
//   event.preventDefault();
// };
// window.addEventListener('keydown', function (e) {
  
//   if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'I') {
//     e.preventDefault();
//   }
// });

root.render(
  
      <AuthProvider>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ReduxProvider>
    </AuthProvider>
 
);
reportWebVitals();
