import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import PrivateRoute from './PrivateRoute';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
   
    
  
    {
      path: '*',
      element: <PrivateRoute />
    },

    
  ]
};

export default MainRoutes;
