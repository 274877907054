import { useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { useAuth } from 'utils/AuthContext';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isAuthenticated } = useAuth();


  return useRoutes(isAuthenticated ?([MainRoutes]):([ LoginRoutes]))  
}
